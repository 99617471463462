import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="../licorne.jpg"
								alt="Fédération Française des licornes"
								className="work-image"
							/>
							<div className="work-title">FFL - Fédération Française des Licornes</div>
							<div className="work-subtitle">
								Directrice des opérations
							</div>
							<div className="work-duration">2014 - Present</div>
						</div>

						<div className="work">
							<img
								src="../princesse.jpg"
								alt="Princesse"
								className="work-image"
							/>
							<div className="work-title">Syndicat des princesses</div>
							<div className="work-subtitle">
								Consultante
							</div>
							<div className="work-duration">2019 - Present</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
