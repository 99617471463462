import React from "react";


function article_1() {
	return {
		date: "15 Juin 2024",
		title: "Chirurgie d'urgence 🚑",
		description:
			"Quand la chute d'un vélo, provoque une blessure requerant les doigts les plus habiles, il nous reste Julien",
		style: ``,
		keywords: [
			""
		],
		body: (
			<React.Fragment>
				<h1>Chirurgie d'urgence 🚑</h1>
				<p>
					Voila ce qu'il arrive si nous manquons de vigilance; la blessure !
					Grace à l'assistance de mes collegues ADORES nous avons pu mettre un terme a cette TERRIBLE situation.
				</p>
				<img
						src="../operation.jpg"
						alt="nothing"
						width={400}
					/>
				<p>
					
					J'insiste, mes collegues sont simplement les meilleurs 💖
				</p>
				<strong>
					Bisous mes licornettes 🦄
				</strong>
			</React.Fragment>
		),
	};
}



function article_2() {
	return {
		date: "4 Juillet 2024",
		title: "Poser un lapin aux collegues",
		description:
			"Pourquoi vous aussi vous devriez poser des lapins à vos collegues #madameconnasse",
		style: ``,
		keywords: [
			"Mettage de lapin",
			"Connasse",
			"Raiponse",
		],
		body: (
			<React.Fragment>
				<h1>Pourquoi vous devriez mettre des lapins à vos collègues ?</h1>
				<p>
					La vie de Raiponce est un long chemin et je ne vous parle pas des jours ou notre ascendant licorne prend le pas !
					Alors revenant ont au sujet, pourquoi mes licornettes nous devrions mettre des lapins à nos collègues aprés les avoirs invités à diner ?
				</p>
				<h3>
					OUI, car nous sommes des licornes simplement !
				</h3>
				<p>
					Sauvage ; indomptable ; qui pourrait croire pouvoir nous accabler d'un models social où meme nous soumettre a cette basse besogne qui es de suivre nos engagement.
					A quel point devriez vous saborder votre naturel pour vous conformer aux autres ? N'oubliez jamais, le mot irrésistible est un des synonymes d'indomptable.

				</p>
				<strong>
					Bisous mes licornettes 🦄
				</strong>
			</React.Fragment>
		),
	};
}

function article_3() {
	return {
		date: "11 Juillet 2024",
		title: "Le bal masqué",
		description:
			"Une princesse sans bal masqué ? Ce soir, on offrira à cette bande de va-nu-pieds l'incroyable, ils verront ce qu'alors ils n'ont jamais vu, la prestance d'une princesse.",
		style: ``,
		keywords: [
			"Bal masqué",
			"Princesse",
			"Raiponce",
		],
		body: (
			<React.Fragment>
				<h1>Si demain n'est pas, alors vous aurez aujourd'hui</h1>
				<p>
					Ce soir le monde s'offre à nous, une occasion en or de démontrer que l'age d'or de Cendrillon est révolu.
					Mais alors, vais-je trouver mon comte ? Je vous raconte tout demain !
				</p>
				<img
						src="../masque.png"
						alt="nothing"
						width={400}
					/>
				<p></p>

				<strong>
					Bisous mes licornettes 🦄
				</strong>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1, article_2, article_3];

export default myArticles;
