import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";

import Homepage from "./pages/homepage";
import About from "./pages/about";
import Projects from "./pages/projects";
import Articles from "./pages/articles";
import ReadArticle from "./pages/readArticle";
import Contact from "./pages/contact";
import Notfound from "./pages/404";

import { TRACKING_ID } from "./data/tracking";
import "./app.css";

function App() {
	const [isVerified, setIsVerified] = useState(false);

	const checkPw = () => {
		// gets the current input value
		const answer = document.getElementById("password").value;

		if (answer === "connasse" 
			|| answer === "conasse"
			|| answer === "Conasse"
			|| answer === "Conasse") {
			setIsVerified(true);
		} else {
			alert("Pas le bon mot de passe mon petit chat 😘");
		}
	};

	useEffect(() => {
		if (TRACKING_ID !== "") {
			ReactGA.initialize(TRACKING_ID);
		}
	}, []);

	return (
		isVerified ?
			<div className="App">
				<Routes>
					<Route path="/" element={<Homepage />} />
					<Route path="/about" element={<About />} />
					<Route path="/projects" element={<Projects />} />
					<Route path="/articles" element={<Articles />} />
					<Route path="/article/:slug" element={<ReadArticle />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="*" element={<Notfound />} />
				</Routes>
			</div>
			:
			(
				<form onSubmit={checkPw} className={'container'}>
					<div>
						<div>"C'est écrit sur mon tee-shirt de pyjama, les meilleurs savent que je suis la plus belle dedans (je ne l'assume pas trop en public, trop de charisme 😘)"</div>
					</div>
					<br />
					<div className={'inputContainer'}>
						<input
						id="password"
						placeholder=""
						className={'inputBox'}
						/>
					</div>
					<br />
					<div className={'inputContainer'}>
						<button className={'inputButton'}>Boite de pandore</button>
					</div>
				</form>
			)
	);
}

export default App;
