const SEO = [
	{
		page: "home",
		description:
			"",
		keywords: ["", ""],
	},

	{
		page: "about",
		description:
			"",
		keywords: [""],
	},

	{
		page: "articles",
		description:
			"",
		keywords: [""],
	},

	{
		page: "projects",
		description:
			"",
		keywords: [""],
	},

	{
		page: "contact",
		description:
			"",
		keywords: [""],
	},
];

export default SEO;
