const INFO = {
	main: {
		title: "La licorne sauvage 🦄",
		name: "Cédrine G.",
		email: "bpotier31380@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		//twitter: "https://twitter.com/",
		//github: "https://github.com/",
		//linkedin: "https://linkedin.com/",
		instagram: "https://instagram.com/cedrine_gtls",
		//stackoverflow: "https://stackoverflow.com/",
		//facebook: "https://facebook.com/",
	},


	homepage: {
		title: "Prodigieuse briseuse de couill*s, vous allez m'adorer",
		description:
			"Échappée d'un compte Disney, en recherche depuis de mon comte. J'embellis tes journées (He oui, c'est moi les pailletes, n'oubliez pas de le dire à Kévin)",
	},

	about: {
		title: "Pour les intimes, moi c'est Raiponce²",
		description:
			"Professionnelle impliqué, femme accomplie. Attaché à agrémenter ta journée d'un soleil quand celui-ci se laisse oublier. Je n'ai jamais raté une occasion de faire quelque chose de stupide, cependant sois rassuré personne ne m'a jamais soupçonnée d'être saine d'esprit. Ma meilleure tenue pour un date c'est un tee-shirt madame connasse 👀",
	},

	articles: {
		title: "Je vous raconte le quotidien d'une princesse ascendant licorne",
		description:
			"Je le sais, j'ai l'impérieuse obligation de vous parler de mon quotidien. Vous trouverez toute la documentation ici pour vous faire découvrir mon quotidien de licorne.",
	},

	projects: [
		{
			title: "Makeup",
			description:
				"Mes meilleurs conseilles make-up dans mes GRWM ",
			logo: "../makeup.jpg",
			linkText: "Abonne toi",
			link: "https://instagram.com/cedrine_gtls",
		},

		{
			title: "Brisage testiculaires",
			description:
				"Quand tes responsabilités de licorne ascendant princesse t'oblige à être insupportable",
			logo: "../couilles.jpg",
			linkText: "J'ai déjà saoulé un rocher",
			link: "https://instagram.com/cedrine_gtls",
		},

		{
			title: "Outfit",
			description:
				"Je te montre mes plus belles tenues, la mode n'auras plus de secret",
			logo: "../otd.jpg",
			linkText: "Viens voir mes plus beaux outfits",
			link: "https://instagram.com/cedrine_gtls",
		},

		{
			title: "Bicyclette",
			description:
				"Après une longue semaine, impossible de ne pas aller se détendre entre deux-roues et un guidon (une batterie c'est pas mal aussi)",
			logo: "../vtt.jpg",
			linkText: "Plus de gadoue",
			link: "https://instagram.com/cedrine_gtls",
		},

		{
			title: "Boutentrain",
			description:
				"A défaut d'une bonne journée, peut-etre que je serais dans le coin",
			logo: "../joie.jpg",
			linkText: "Je veux de la rigolade",
			link: "https://instagram.com/cedrine_gtls",
		},
	],
};

export default INFO;
